import React from 'react';
// import PropTypes from 'prop-types';
import logo from 'assets/img/logo-alt.svg';

function AppPage404() {
  return (
    <div className="bg-primary-500 fixed h-screen w-screen top-0 left-0 flex">
      <div className="m-auto flex flex-col justify-center">
        <div className="mx-auto">
          <img className="w-full object-cover" src={logo} alt="logo" />
        </div>
        <div className="text-center mt-4 font-semibold text-white">
          <a
            className="text-white underline text-lg font-thin"
            href="/app/close"
          >
            You may now close this window
          </a>
        </div>
      </div>
    </div>
  );
}

AppPage404.propTypes = {};

export default AppPage404;
