import React from 'react';
import Header from '../landing/Header';
import HeroMain from '../landing/HeroMain';
import About from '../landing/About';
import FeatureBuy from '../landing/FeatureBuy';
import FeatureScan from '../landing/FeatureScan';
import FeatureWallet from '../landing/FeatureWallet';
import Download from '../landing/Download';
import Footer from '../landing/Footer';

function Landing() {
  return (
    <>
      <Header />
      <HeroMain />
      <About />
      <FeatureBuy />
      <FeatureScan />
      <FeatureWallet />
      <Download />
      <Footer />
    </>
  );
}

Landing.propTypes = {};

export default Landing;
