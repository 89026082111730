import { req } from 'react-reqq';
import * as t from './transforms';
import * as c from './constants';

export const listTicket = (params) => {
  req.get({
    key: c.TICKET_LIST,
    url: () =>
      `${process.env.REACT_APP_WALLET_END_POINT}/api/my/transaction/tickets`,
    headers: (header) => {
      const token = (sessionStorage.getItem('-app-token-') || '').replace(
        /"/g,
        ''
      );
      return {
        ...header,
        Authorization: `Bearer ${token}`,
      };
    },
    params,
    transform: (res) =>
      (res?.data?.tickets || []).map((item) => t.transformTicket2(item)),
  });
};

export const getTicket = (refno) => {
  req.get({
    key: c.TICKET,
    url: `/v1/ticket/${refno}`,
    transform: (res) => {
      const data = t.transformTicket(res?.data || {});
      return data;
    },
  });
};

export const setTicketState = (newState) => {
  req.set(c.TICKET, (state) => ({
    ...(state[c.TICKET] || {}),
    ...newState,
  }));
};
