import React from 'react';
// import PropTypes from 'prop-types';
import { Route, Switch, useLocation, useRouteMatch } from 'react-router-dom';
import AudioService from 'modules/partial/containers/AudioService';
import TicketDetail from './TicketDetail';
import TicketList from './TicketList';

function TicketContainer() {
  const location = useLocation();
  const match = useRouteMatch();
  return (
    <div className="h-full w-full flex flex-col relative max-w-lg mx-auto">
      <Switch location={location}>
        <Route exact path={match.path}>
          <TicketList />
        </Route>
        <Route exact path={`${match.path}/:refno`}>
          <TicketDetail />
        </Route>
      </Switch>
      <AudioService />
    </div>
  );
}

TicketContainer.propTypes = {};

export default TicketContainer;
