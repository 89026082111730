import React from 'react';
import PropTypes from 'prop-types';
import DayPicker from 'modules/partial/forms/DayPicker';
import { useApiGet } from 'react-reqq';
import { formatNumber } from 'modules/partial/helper';
import * as actions from '../actions';
import * as c from '../constants';

function ConductorDashboard({ filter, setFilter }) {
  const data = useApiGet(c.TICKET_AGGS, {});
  React.useEffect(() => {
    actions.getDashboard({
      from: filter.date,
      to: filter.date,
    });
  }, [filter]);
  return (
    <div className="py-4">
      <div className="flex justify-center mb-2">
        <DayPicker name="date" onChange={setFilter} value={filter.date} />
      </div>
      <div className="grid grid-cols-2 gap-2">
        <div className="text-center">
          <div className="text-sm text-gray-600"># of Transactions:</div>
          <div className="text-lg text-gray-800">
            {formatNumber(data.total_count || '0', 0)}
          </div>
        </div>
        <div className="text-center">
          <div className="text-sm text-gray-600">Total Amount:</div>
          <div className="text-lg text-gray-800">
            {formatNumber(data.total_amount || '0')}
          </div>
        </div>
      </div>
    </div>
  );
}

ConductorDashboard.propTypes = {
  filter: PropTypes.instanceOf(Object).isRequired,
  setFilter: PropTypes.func.isRequired,
};

export default ConductorDashboard;
