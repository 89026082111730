import { req } from 'react-reqq';
import { storage, transformIncluded } from 'modules/partial/helper';
import { transformTicket } from 'modules/ticket/transforms';
import * as c from './constants';

export const autoLogin = (appToken, onSuccess, onError) => {
  req.post({
    key: c.AUTH,
    url: () => `${process.env.REACT_APP_WALLET_END_POINT}/api/operator/token`,
    headers: (header) => {
      return {
        ...header,
        Authorization: `Bearer ${appToken}`,
      };
    },
    onSuccess: (res) => {
      const token = res?.response?.data?.token;
      sessionStorage.setItem('-conductor-token-', token);
      const data = {
        isAuthenticated: !!token,
        profile: {
          name: 'Conductor User',
        },
      };
      req.set(c.AUTH, data);
      storage.set('conductor-auth', data);
      if (onSuccess) onSuccess();
    },
    onError,
  });
};

export const login = (payload) => {
  req.post({
    key: c.AUTH,
    url: '/v1/vendor/auth',
    payload,
    onSuccess: (res) => {
      const token = res?.response?.token;
      sessionStorage.setItem('-conductor-token-', token);
      const data = {
        isAuthenticated: !!token,
        profile: {
          name: payload.email,
        },
      };
      req.set(c.AUTH, data);
      storage.set('conductor-auth', data);
    },
  });
};

export const listTicket = (params) => {
  req.get({
    key: c.TICKET,
    url: '/v1/personnel/ticket',
    params,
    headers: (header) => {
      const token = (sessionStorage.getItem('-conductor-token-') || '').replace(
        /"/g,
        ''
      );
      return {
        ...header,
        Authorization: `Bearer ${token}`,
      };
    },
    transform: (res) => (res.data || []).map((item) => transformTicket(item)),
  });
};

export const scanTicket = (payload, onSuccess) => {
  req.post({
    key: c.SCAN,
    // url: `/v1/ticket/${ticket_no}`,
    url: '/v1/personnel/ticket',
    payload,
    headers: (header) => {
      const token = (sessionStorage.getItem('-conductor-token-') || '').replace(
        /"/g,
        ''
      );
      return {
        ...header,
        Authorization: `Bearer ${token}`,
      };
    },
    onSuccess: (res) => {
      const ticket = transformTicket(res?.response?.data || {});
      ticket.status = 'PENDING';
      req.set(c.SCAN, ticket);
      onSuccess();
    },
    onError: (res) => {
      const by_status = {
        400: 'USED',
        422: 'ERROR',
      };
      req.set(c.SCAN, {
        messsage: 'Ticket not found',
        status: by_status[res.status] || 'ERROR',
      });
    },
  });
};

export const getDashboard = (params) => {
  req.get({
    key: c.TICKET_AGGS,
    url: '/v1/personnel/ticket/aggs',
    params,
    headers: (header) => {
      const token = (sessionStorage.getItem('-conductor-token-') || '').replace(
        /"/g,
        ''
      );
      return {
        ...header,
        Authorization: `Bearer ${token}`,
      };
    },
    transform: (res) => ({
      total_count: res?.total_count,
      total_amount: res?.total_amount,
    }),
  });
};

export const listSchedule = (params) => {
  req.get({
    key: c.SCHEDULE,
    url: '/v1/personnel/schedules',
    params,
    headers: (header) => {
      const token = (sessionStorage.getItem('-conductor-token-') || '').replace(
        /"/g,
        ''
      );
      return {
        ...header,
        Authorization: `Bearer ${token}`,
      };
    },
    transform: (res) =>
      (res.data || []).map((item) =>
        transformIncluded(item, res.included || [])
      ),
  });
};

export const selectSchedule = (uuid) => {
  req.get({
    key: c.SCHEDULE_ITEM,
    url: `/v1/personnel/schedules/${uuid}`,
    headers: (header) => {
      const token = (sessionStorage.getItem('-conductor-token-') || '').replace(
        /"/g,
        ''
      );
      return {
        ...header,
        Authorization: `Bearer ${token}`,
      };
    },
    transform: (res) => transformIncluded(res.data || {}, res.included || []),
  });
};
