import React from 'react';
// import PropTypes from 'prop-types';
import logo from 'assets/img/logo.svg';

function AppNoAuth() {
  const [isLoading, setIsLoading] = React.useState(true);
  React.useEffect(() => {
    const timeout = setTimeout(() => setIsLoading(false), 1000);
    return () => {
      clearTimeout(timeout);
    };
  }, []);
  return (
    <div className="bg-white fixed h-screen w-screen top-0 left-0 flex">
      <div className="m-auto flex flex-col justify-center">
        <div className="mx-auto">
          <img className="w-full object-cover" src={logo} alt="logo" />
        </div>
        <div className="text-center mt-4 font-semibold text-primary-500">
          {isLoading ? 'Authenticating...' : 'Unable to authenticate'}
        </div>
        <div
          className={`text-center mt-4 font-semibold text-primary-500 ${
            isLoading ? 'opacity-0' : ''
          }`}
        >
          <a
            className="text-primary-500 underline text-sm font-thin"
            href="/app/close"
          >
            You may now close this window
          </a>
        </div>
      </div>
    </div>
  );
}

AppNoAuth.propTypes = {};

export default AppNoAuth;
