import React from 'react';
// import PropTypes from 'prop-types';

function Terms() {
  const email = 'dpo@multisyscorp.com';

  return (
    <div className="font-thin with-indent">
      <h1 className="font-medium text-2xl text-primary-500">
        TERMS AND CONDITIONS{' '}
      </h1>

      <h2 className="font-medium mt-4 text-lg text-primary-500">GENERAL</h2>
      <p className="mb-3">
        Terms and Conditions contained herein on this mobile app (the “App”)
        shall govern your use of this App, including all pages which you may be
        redirected. These Terms apply in full force and effect to your use of
        this App and by using this App, you expressly accept all terms and
        conditions contained herein in full. You must not use this App, if you
        have any objection to any of these Terms and Conditions.
      </p>
      <p className="mb-3">
        Whenever used in herein, “App Owner” shall mean DITO CME
      </p>

      <h2 className="font-medium mt-4 text-lg text-primary-500">AUTHORITY</h2>
      <p className="mb-3">
        By registering in the App, you warrant that you are duly authorized to
        register, request access, and supply information. The App Owner may at
        its sole discretion request for additional or supporting documents to
        prove such authority.
      </p>
      <p className="mb-3">
        You also warrant that all information which will be supplied are
        verified and true and correct to the best of your knowledge.
      </p>

      <h2 className="font-medium mt-4 text-lg text-primary-500">USE</h2>
      <p className="mb-3">
        By having registering and having access to the App, you shall only use
        it for the following purpose:
      </p>
      <ul className="list-decimal pl-8">
        <li>Creation of account</li>
        <li>Loading of Mobile App Wallet</li>
        <li>Purchase of transportation tickets </li>
        <li>Payment of transportation tickets</li>
        <li>Access and download of e-tickets for transportation</li>
        <li>
          Transfer of fund or credits from you App to another TranspoPay App
        </li>
      </ul>

      <h2 className="font-medium mt-4 text-lg text-primary-500">
        RESTRICTIONS
      </h2>
      <p className="mb-3">
        You are expressly and emphatically restricted from all of the following:
      </p>
      <ul className="list-decimal pl-8">
        <li>Selling, sublicensing and/or otherwise commercializing the App;</li>
        <li>
          Using the App or any of the information in illegal or unlawful
          activities;
        </li>
        <li>Publicly performing and/or showing any App material;</li>
        <li>
          Using this App in any way that is, or may be, damaging to this App;
        </li>
        <li>Using this App in any way that impacts user access to this App;</li>
        <li>
          Using this App contrary to applicable laws and regulations, or in a
          way that causes, or may cause, harm to the App, or to the App Owner or
          any person or organization;
        </li>
        <li>
          Engaging in any data mining, data harvesting, data extracting or any
          other similar activity in relation to this App, or while using this
          App;
        </li>
        <li>Using this App to engage in any advertising or marketing;</li>
        <li>
          Certain areas of this App are restricted from access by you and by the
          App Owner may further restrict access by you to any areas of this App,
          at any time, in its sole and absolute discretion. Personal information
          you may have shared for this App are confidential.
        </li>
      </ul>

      <h2 className="font-medium mt-4 text-lg text-primary-500">PROFILE</h2>
      <ul className="list-decimal pl-8">
        <li>
          In this Terms and Conditions, all information shall be treated as
          confidential. With respect to the information you grant the App Owner
          a full view of your information (as may be applicable based on the
          information supplied) and also allow the latter to use the information
          if needed according to the Data Privacy Act of 2012.
        </li>
        <li>
          By using and accessing this App, you also agree to the App’s PRIVACY
          NOTICE and that you agree to exercise the same diligence as the App
          Owner in implementing the App’s Privacy policy.
        </li>
      </ul>

      <h2 className="font-medium mt-4 text-lg text-primary-500">
        LIMITATION OF LIABILITY
      </h2>
      <p className="mb-3">
        In no event shall the App Owner nor any of its officers, directors and
        employees, be liable to you for anything arising out of or in any way
        connected with your use of this App, whether such liability is under
        contract, tort or otherwise, and the App Owner, including its officers,
        directors and employees shall not be liable for any indirect,
        consequential or special liability arising out of or in any way related
        to your use of this App or any breach therein.
      </p>

      <h2 className="font-medium mt-4 text-lg text-primary-500">
        INDEMINIFICATION
      </h2>
      <p className="mb-3">
        You hereby indemnify to the fullest extent the App Owner from and
        against any and all liabilities, costs, demands, causes of action,
        damages and expenses (including reasonable attorney’s fees) arising out
        of or in any way related to your breach of any of the provisions of
        these Terms, the and the Privacy Notice.
      </p>

      <h2 className="font-medium mt-4 text-lg text-primary-500">
        UPDATE IN TERMS
      </h2>
      <p className="mb-3">
        The App Owner is permitted to revise these Terms at any time as it sees
        fit, and by using this App you are expected to review such Terms on a
        regular basis to ensure you understand all terms and conditions
        governing use of this App.
      </p>

      <h2 className="font-medium mt-4 text-lg text-primary-500">CONTACT US</h2>
      <p className="mb-3">
        If you have any question pertaining to this App or any of the Terms and
        Conditions contained herein, please contact the App Owner at{' '}
        <a className="text-primary-500" href={`mailto:${email}`}>
          {email}
        </a>
        .
      </p>
    </div>
  );
}

Terms.propTypes = {};

Terms.defaultProps = {};

export default Terms;
