import React from 'react';
import PropTypes from 'prop-types';
import arrowLeft from 'assets/img/buy-ticket/arrow-left.svg';
import { useHistory } from 'react-router-dom';
import { formatNumber } from 'modules/partial/helper';
import { useApiGet, useApiLoading } from 'react-reqq';
import { isEmpty } from 'lodash';
import * as actions from '../actions';
import * as c from '../constants';

const Fare = ({ data }) => {
  if (data.isDiscounted) {
    return (
      <>
        <div className="font-light tracking-tight text-green-500 text-4xl text-center mx-auto mt-2">
          <span>&#x20B1; {formatNumber(data.discountedPrice)}</span>{' '}
          <span className="line-through text-base text-gray-600">
            &#x20B1; {formatNumber(data.price)}
          </span>
        </div>
        <div className="text-center leading-8 border-t pt-3">
          <div className="text-sm font-light leading-none text-center">
            Discounts Applied
          </div>
          {data.appliedDiscounts.map((item) => (
            <div key={item}>{item}</div>
          ))}
        </div>
      </>
    );
  }
  return (
    <div className="font-light tracking-tight text-green-500 text-4xl text-center mx-auto mt-2">
      &#x20B1; {formatNumber(data.price)}
    </div>
  );
};

Fare.propTypes = {
  data: PropTypes.instanceOf(Object).isRequired,
};

function BuyTicket({ typeId }) {
  const [error, setError] = React.useState(false);
  const history = useHistory();
  const fare = useApiGet(c.GET_PRICE, {});
  const isGettingPrice = useApiLoading(c.GET_PRICE, 'get');
  const isLoading = useApiLoading(c.BUY_TICKET, 'post');
  const origin = useApiGet(c.TICKET_ORIGIN, {
    id: 'b26dc590-f800-43a3-b369-d216864b52b2',
    name: 'Taft Avenue',
  });
  const destination = useApiGet(c.TICKET_DESTINATION, {
    id: '26440b54-2be3-4bfb-94d4-4488e56b8f83',
    name: 'Magallanes',
  });
  const handleSubmit = () => {
    const payload = {
      operator_uuid: typeId,
      origin_uuid: origin.id,
      destination_uuid: destination.id,
    };
    actions.buyTicket(
      payload,
      (refno) => {
        history.push({
          pathname: `/app/ticket/${refno}`,
          state: {
            backUrl: '/app/close',
          },
        });
      },
      (res) => {
        setError({
          message: res?.response?.message || 'Unable to make transaction!',
        });
      }
    );
  };
  return (
    <div className="relative z-10 p-4 mb-auto">
      <div className="bg-white rounded-lg shadow-md">
        {error ? (
          <>
            <div className="p-4 border-b">
              <div className="text-lg font-light leading-none text-center text-red-500">
                {error?.message || 'Error'}
              </div>
            </div>
            <div className="p-4">
              <button
                className="btn primary w-full"
                type="button"
                onClick={() => setError(false)}
              >
                Ok
              </button>
            </div>
          </>
        ) : (
          <>
            <div className="p-4 border-b">
              <div className="text-sm font-light leading-none text-center">
                Your Fare
              </div>
              <div className="font-light text-xs flex items-center justify-center">
                <span className="max-w-24 truncate text-gray-500">
                  {origin?.name}
                </span>
                <img className="h-3 mx-2" src={arrowLeft} alt="chevron" />
                <span className="max-w-24 truncate text-gray-500">
                  {destination?.name}
                </span>
              </div>
              {fare.isAvailable ? (
                <Fare data={fare} />
              ) : (
                <div className="font-light tracking-tight text-4xl text-center mx-auto mt-2">
                  {isEmpty(fare) ? (
                    <span className="text-primary-500">Please wait</span>
                  ) : (
                    <span className="text-red-500">Not Available</span>
                  )}
                </div>
              )}
            </div>
            <div className="p-4">
              <button
                className="btn primary w-full"
                type="button"
                onClick={handleSubmit}
                disabled={isGettingPrice || isLoading || !fare.isAvailable}
              >
                Buy Ticket
              </button>
            </div>
          </>
        )}
      </div>
    </div>
  );
}

BuyTicket.propTypes = {
  typeId: PropTypes.string.isRequired,
};

export default BuyTicket;
