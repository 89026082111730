import React from 'react';
// import PropTypes from 'prop-types';

function Privacy() {
  const email = 'dpo@multisyscorp.com';

  return (
    <div className="font-thin">
      <h1 className="font-medium text-2xl text-primary-500">PRIVACY NOTICE</h1>
      <p className="mb-3">
        This Privacy Notice explains what information is collected, processed,
        and/or shared; the purposes; and your rights with regard to your
        information. We process your information in accordance with Republic Act
        No. 10173 (the “Data Privacy Act”).
      </p>
      <p className="mb-3">
        We may amend this Privacy Notice from time to time. When certain changes
        require your consent, we shall communicate the changes to you and ask
        for your consent. Otherwise, changes take effect immediately upon
        posting.
      </p>

      <h2 className="font-medium mt-4 text-lg text-primary-500">
        INFORMATION COLLECTED AND THE PURPOSE
      </h2>
      <p className="mb-3">
        When you register to TranspoPay, only CONTACT NUMBER will be taken. This
        shall be used for registration, security purpose and for the generation
        of a One-Time-Password (OTP).
      </p>
      <p className="mb-3">
        Camera Usage. This may be enabled for QR Code scanning purpose.
      </p>

      <h2 className="font-medium mt-4 text-lg text-primary-500">
        USE AND RETENTION OF INFORMATION
      </h2>
      <p className="mb-3">
        We use the data you supplied for your account registration and
        processing. Your information is taken only for the purpose your use of
        the App.
      </p>
      <p className="mb-3">
        The data collected will be stored in a secure environment. Should you
        wish to have the data collected from you deleted and destroyed or you
        wish to withdraw your consent in the processing of the same, you may do
        so by the Owner.
      </p>

      <h2 className="font-medium mt-4 text-lg text-primary-500">
        SECURITY OF THE DATA COLLECTED
      </h2>
      <p className="mb-3">
        We implement reasonable and appropriate physical, technical, and
        organizational measures to prevent the loss, destruction, misuse, or
        alteration of your information or the data collected. While there is no
        guarantee that information sent over the internet is fully secured, we
        keep and protect your information using a secured server behind a
        firewall, encryption, and security controls.
      </p>

      <h2 className="font-medium mt-4 text-lg text-primary-500">
        EXERCISING YOUR RIGHTS
      </h2>
      <p className="mb-3">
        Although we only collect your Contact Number, the Data Privacy Act of
        2012 gives you certain rights to your information.
      </p>
      <ul className="list-decimal pl-8">
        <li>Right to be informed</li>
        <li>Right to access</li>
        <li>Right to object</li>
        <li>Right to erasure of blocking</li>
        <li>Right to rectify</li>
        <li>Right to data portability</li>
        <li>Right to file a complaint</li>
        <li>Right to damages</li>
      </ul>

      <p className="mb-3">
        If you want to exercise your rights or learn more about how TranspoPay
        processes the data collected from you, please contact our Data
        Protection Officer at{' '}
        <a className="text-primary-500" href={`mailto:${email}`}>
          {email}
        </a>
        .
      </p>
    </div>
  );
}

Privacy.propTypes = {};

Privacy.defaultProps = {};

export default Privacy;
