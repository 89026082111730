import React from 'react';
// import PropTypes from 'prop-types';
import AppHeader from 'modules/partial/components/AppHeader';
import QrScanner from 'modules/partial/components/QrScanner';
import ticketTop from 'assets/img/buy-ticket/ticket-top.png';
import ticketBottom from 'assets/img/buy-ticket/ticket-bottom.png';
import pin from 'assets/img/buy-ticket/pin.svg';
import success from 'assets/img/conductor/success.svg';
import expire from 'assets/img/conductor/expire.svg';
import error from 'assets/img/conductor/error.svg';
import { useParams } from 'react-router-dom';
import { formatNumber } from 'modules/partial/helper';
import { isEmpty } from 'lodash';
import { req, useApiGet, useApiLoading } from 'react-reqq';
import * as actions from '../actions';
import * as c from '../constants';

const TIMEOUT = 10000;

const LABEL = {
  USED: 'Ticket is used',
  PENDING: 'Scan Ticket Successful',
  EXPIRED: 'Scan Expired',
  ERROR: 'Scan Not Found',
};

const ICON = {
  USED: expire,
  PENDING: success,
  EXPIRED: expire,
  ERROR: error,
};

let resetTimeout;
let tickInterval;

function ConductorScanner() {
  const { trip_schedule_uuid } = useParams();
  const [refno, setRefno] = React.useState(null);
  const refnoRef = React.useRef();
  const isLoading = useApiLoading(c.SCAN, 'post');
  const result = useApiGet(c.SCAN, {});
  const firebase = useApiGet('FIREBASE', null);
  const handleReset = () => {
    clearTimeout(resetTimeout);
    clearInterval(tickInterval);
    setRefno(null);
    refnoRef.current = null;
    req.set(c.SCAN, {});
  };
  const [tick, setTick] = React.useState(TIMEOUT / 1000);
  const handleClose = (e) => {
    e.preventDefault();
    clearInterval(tickInterval);
    handleReset();
  };
  React.useEffect(() => {
    if (refnoRef.current || !refno || !firebase) return;

    const cleanRefno = refno.replace(/[^0-9a-z]/gi, '');
    refnoRef.current = cleanRefno;
    const database = firebase.database();
    const dbRef = database.ref(`ticket/${cleanRefno}`);
    const payload = {
      ticket_no: cleanRefno.substring(0, 7),
      trip_schedule_uuid,
    };
    actions.scanTicket(payload, () => {
      dbRef.set({ status: 'USED' });
      setTick(TIMEOUT / 1000);
      tickInterval = setInterval(() => {
        setTick((state) => {
          const newTick = state - 1;
          if (newTick < 1) clearInterval(tickInterval);
          return newTick;
        });
      }, 1000);
      resetTimeout = setTimeout(handleReset, TIMEOUT);
    });
  }, [refno, firebase, trip_schedule_uuid]);
  React.useEffect(() => {
    return () => {
      req.set(c.SCAN, {});
    };
  }, []);
  return (
    <>
      <div className="absolute top-0 left-0 w-full z-20">
        <AppHeader
          title="Scan Ticket"
          backUrl={`/app/conductor/schedules/${trip_schedule_uuid}`}
          transparent
        />
      </div>
      <div
        id="scanner-wrapper"
        className="flex flex-grow bg-black relative z-10"
      >
        <QrScanner onScan={(value) => setRefno(encodeURIComponent(value))} />
      </div>
      {refno && (
        <div className="fixed top-0 right-0 h-full w-full bg-secondary-500 bg-opacity-75 z-50">
          <div className="h-full w-full absolute flex flex-col px-8 mt-20">
            <div className="w-full">
              <img className="w-full" src={ticketTop} alt="top" />
            </div>
            <div className="bg-white relative">
              {isLoading && (
                <div className="absolute h-full w-full flex flex-col py-3">
                  <div className="animate-pulse w-32 h-4 bg-gray-200 block mx-auto mt-16 mb-2" />
                  <div className="animate-pulse w-40 h-4 bg-gray-200 block mx-auto mt-16" />
                  <div className="animate-pulse w-40 h-4 bg-gray-200 block mx-auto mt-16" />
                </div>
              )}
              <div className="p-4 flex flex-col">
                <img
                  className="h-12 mx-auto mb-2"
                  src={ICON[result.status] || pin}
                  alt="top"
                />
                <div className="text-primary-500 font-light m-auto">
                  {result.status ? (
                    <span>{LABEL[result.status] || result.status}</span>
                  ) : (
                    <span>&nbsp;</span>
                  )}
                </div>
              </div>
              <div className="p-4 border-t border-b flex flex-col items-center text-center">
                <div>
                  <img src={pin} alt="drop" />
                </div>
                <div>
                  <div className="text-gray-500 font-thin text-xs">
                    Leaving From
                  </div>
                  <div className="text-sm truncate w-48">
                    {result?.origin ? (
                      <span>{result?.origin?.name}</span>
                    ) : (
                      <span>-</span>
                    )}
                  </div>
                </div>
                <div className="border-r-2 h-8 border-gray-500 border-dashed flex-grow my-1" />
                <div>
                  <div className="text-gray-500 font-thin text-xs">
                    Going To
                  </div>
                  <div className="text-sm truncate w-48">
                    {result?.destination ? (
                      <span>{result?.destination?.name}</span>
                    ) : (
                      <span>-</span>
                    )}
                  </div>
                </div>
                <div>
                  <img src={pin} alt="drop" />
                </div>
              </div>
              <div className="p-4" style={{ minHeight: 70 }}>
                {result.status !== 'PENDING' ? (
                  <div>
                    {!isEmpty(result) && (
                      <button
                        className="btn primary rounded-full w-full block"
                        onClick={handleReset}
                        disabled={isLoading}
                        type="button"
                      >
                        Scan Again
                      </button>
                    )}
                  </div>
                ) : (
                  <div className="text-center">
                    <div className="text-gray-500 font-thin text-xs">
                      Ticket Fare
                    </div>
                    <div className="font-light text-green-500 text-2xl ml-auto">
                      &#x20B1; {formatNumber(result.amount)}
                    </div>
                    <a
                      className="text-sm underline text-gray-500"
                      onClick={handleClose}
                      href="/"
                    >
                      Closing in ({tick})
                    </a>
                  </div>
                )}
              </div>
            </div>
            <div className="w-full">
              <img
                className="w-full ticket-shadow"
                src={ticketBottom}
                alt="bottom"
              />
            </div>
          </div>
        </div>
      )}
    </>
  );
}

ConductorScanner.propTypes = {};

export default ConductorScanner;
