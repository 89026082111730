import React from 'react';
import { Switch, Route, useRouteMatch, useLocation } from 'react-router-dom';
import { useFirebase } from 'modules/partial/hooks';
import AppPage404 from 'modules/partial/containers/AppPage404';
import AppNoAuth from 'modules/partial/containers/AppNoAuth';
import BuyTicketContainer from 'modules/buy-ticket/containers/BuyTicketContainer';
import TicketContainer from 'modules/ticket/containers/TicketContainer';
import ConductorRoutes from 'modules/conductor/containers/ConductorRoutes';
import { usePersistState } from 'modules/partial/helper';
import qs from 'query-string';

const PrivateRoute = (props) => {
  const location = useLocation();
  const [auth, setAuth] = usePersistState('-app-auth-', {
    isAuthenticated: false,
    token: null,
  });
  React.useEffect(() => {
    const query = qs.parse(location.search);
    if (!query.token) return;
    sessionStorage.setItem('-app-token-', query.token);
    setAuth({
      isAuthenticated: true,
      token: query.token,
    });
  }, []); // eslint-disable-line
  if (!auth.isAuthenticated) return <Route component={AppNoAuth} />;
  return <Route {...props} />;
};

function Private() {
  useFirebase();
  const match = useRouteMatch();
  return (
    <Switch>
      <PrivateRoute path={`${match.path}/ticket`} component={TicketContainer} />
      <PrivateRoute
        path={`${match.path}/buy-ticket`}
        component={BuyTicketContainer}
      />
      <PrivateRoute
        path={`${match.path}/conductor`}
        component={ConductorRoutes}
      />
      <Route path={`${match.url}/close`} component={AppPage404} />
      <Route component={AppPage404} />
    </Switch>
  );
}

Private.propTypes = {};

export default Private;
