import React from 'react';
// import PropTypes from 'prop-types';
import Terms from '../components/Terms';
import Header from '../landing/Header';
import Footer from '../landing/Footer';

function TermsContainer() {
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <Header />
      <div className="container mx-auto pb-12">
        <Terms />
      </div>
      <Footer />
    </>
  );
}

TermsContainer.propTypes = {};

export default TermsContainer;
