import React from 'react';
import PropTypes from 'prop-types';
import backIcon from 'assets/img/buy-ticket/arrow-right.svg';
import backIconLight from 'assets/img/buy-ticket/arrow-right-light.svg';
import { useHistory } from 'react-router-dom';

function AppHeader({ title, backUrl, primary, secondary, transparent }) {
  const history = useHistory();
  const handleGoBack = () => {
    if (typeof backUrl === 'function') {
      backUrl();
      return;
    }
    if (backUrl.indexOf('/app/close') > -1) {
      window.location.href = backUrl;
      return;
    }
    history.push(backUrl);
  };
  const bgClassName = React.useMemo(() => {
    if (primary) return 'bg-primary-500';
    if (secondary) return 'bg-secondary-500';
    if (transparent) return 'bg-transparent';
    return 'bg-white';
  }, [primary, secondary, transparent]);
  const bgTextClass = React.useMemo(() => {
    if (primary || transparent) return 'text-white';
    return 'text-primary-500';
  }, [primary, transparent]);
  const returnIcon = React.useMemo(() => {
    if (primary || transparent) return backIconLight;
    return backIcon;
  }, [primary, transparent]);
  return (
    <div className={`flex relative ${bgClassName} h-16 flex items-center`}>
      <div className={`m-auto ${bgTextClass} font-light px-16`}>{title}</div>
      {backUrl && (
        <div className="absolute left-0">
          <button className="btn link" onClick={handleGoBack} type="button">
            <img src={returnIcon} alt="back" />
          </button>
        </div>
      )}
    </div>
  );
}

AppHeader.defaultProps = {
  backUrl: '',
  primary: false,
  secondary: false,
  transparent: false,
};

AppHeader.propTypes = {
  backUrl: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
  title: PropTypes.string.isRequired,
  primary: PropTypes.bool,
  secondary: PropTypes.bool,
  transparent: PropTypes.bool,
};

export default AppHeader;
