import React from 'react';
// import PropTypes from 'prop-types';
import AppHeader from 'modules/partial/components/AppHeader';
import { useHistory, useParams } from 'react-router-dom';
import pin from 'assets/img/buy-ticket/pin-alt.svg';
import { req, useApiGet, useApiLoading } from 'react-reqq';
import * as actions from '../actions';
import * as c from '../constants';

function BuyTicketOrigin() {
  const history = useHistory();
  const { type_id } = useParams();
  const list = useApiGet(c.OPERATOR_TERMINAL, []);
  const isLoading = useApiLoading(c.OPERATOR_TERMINAL, 'get');
  const handleSelectRow = (row) => (e) => {
    e.preventDefault();
    req.set(c.TICKET_ORIGIN, row);
    history.push(`/app/buy-ticket/${type_id}`);
  };
  React.useEffect(() => {
    actions.listOperatorTerminals(type_id);
  }, [type_id]);
  const destination = useApiGet(c.TICKET_DESTINATION, {});
  const newList = React.useMemo(
    () => list.filter((item) => `${item.id}` !== `${destination.id}`),
    [list, destination]
  );
  return (
    <>
      <AppHeader
        title="Set Origin"
        backUrl={`/app/buy-ticket/${type_id}`}
        primary
      />
      <div className="flex-grow overflow-auto relative">
        <div className="h-full w-full absolute  flex flex-col divide-y">
          {newList.length < 1 ? (
            <div className="m-auto">
              <div className="text-primary-500 font-semibold p-5 text-center w-full">
                {isLoading ? 'Loading...' : 'No items available.'}
              </div>
            </div>
          ) : (
            newList.map((item) => (
              <a
                className="w-full flex items-center h-16 bg-white px-6 hover:bg-gray-200 py-4"
                key={item.id}
                href="/"
                onClick={handleSelectRow(item)}
              >
                <div className="mr-4">
                  <img className="h-4" src={pin} alt="chevron" />
                </div>
                <div className="font-light">{item.name}</div>
              </a>
            ))
          )}
        </div>
      </div>
    </>
  );
}

BuyTicketOrigin.propTypes = {};

export default BuyTicketOrigin;
