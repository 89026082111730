import React from 'react';
// import PropTypes from 'prop-types';
import Terms from '../components/Terms';

function AppTerms() {
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <div className="container mx-auto pb-12">
        <Terms />
      </div>
    </>
  );
}

AppTerms.propTypes = {};

export default AppTerms;
