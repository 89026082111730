import React from 'react';
// import PropTypes from 'prop-types';
import google_play from 'assets/img/google-play.png';
import app_store from 'assets/img/app-store.png';

const appStoreUrl = 'https://apps.apple.com/us/app/autopay-ph/id1536868354';
const playStoreUrl =
  'https://play.google.com/store/apps/details?id=com.multisyscorp.autopay';

function AppStore() {
  return (
    <div className="flex">
      <div className="mr-4">
        <a href={appStoreUrl} target="_blank" rel="noopener noreferrer">
          <img className="h-12" src={app_store} alt="app_store" />
        </a>
      </div>
      <div>
        <a href={playStoreUrl} target="_blank" rel="noopener noreferrer">
          <img className="h-12" src={google_play} alt="google_play" />
        </a>
      </div>
    </div>
  );
}

AppStore.propTypes = {};

export default AppStore;
