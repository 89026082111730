import React from 'react';
import AppStore from '../components/AppStore';
// import PropTypes from 'prop-types';
// import logo from 'assets/img/logo.svg';

function Download() {
  return (
    <div className="bg-gradient-primary gradient-top py-16">
      <div className="container mx-auto flex">
        <div className="m-auto text-center text-white">
          <div className="leading-none text-4xl mb-8 font-thin">
            Travel with ease
            <br />
            using TranspoPay
          </div>
          <div className="leading-none mb-8 font-light tracking-wider">
            Download TranspoPay now
          </div>
          <div className="mx-auto">
            <AppStore />
          </div>
        </div>
      </div>
    </div>
  );
}

Download.propTypes = {};

export default Download;
