import React from 'react';
// import PropTypes from 'prop-types';
import logo from 'assets/img/logo.png';
import { Link } from 'react-router-dom';

function Header() {
  const [showNav, setShowNav] = React.useState(false);

  const handleScrollTo = (targetId) => () => {
    setTimeout(() => {
      const elem = document.getElementById(targetId);
      if (elem) {
        elem.scrollIntoView({ behavior: 'smooth' });
        setShowNav(false);
      }
    }, 150);
  };
  const handleToggle = () => {
    setShowNav((state) => !state);
  };
  return (
    <div id="home" className="bg-white h-24 sm:h-32 flex items-center">
      <div className="container mx-auto flex items-center">
        <button
          className="p-3 block sm:hidden btn mr-2"
          type="button"
          onClick={handleToggle}
        >
          <span className="h-1 w-8 block bg-primary-700 rounded mb-2" />
          <span className="h-1 w-8 block bg-primary-700 rounded mb-2" />
          <span className="h-1 w-8 block bg-primary-700 rounded" />
        </button>
        <div className="mr-10">
          <img className="h-auto" src={logo} alt="TranspoPay" />
        </div>
        <div
          className={`${
            showNav ? 'flex' : 'hidden'
          } sm:flex fixed sm:relative bg-white sm:bg-transparent shadow-lg sm:shadow-none h-full top-0 left-0 w-64 sm:w-full p-6 z-50 flex-col sm:flex-row`}
        >
          <div className="block sm:hidden flex mb-12 mt-2 ml-1">
            <button
              className="block sm:hidden mr-5"
              type="button"
              onClick={handleToggle}
            >
              <span className="h-1 w-8 block bg-primary-700 rounded mb-2" />
              <span className="h-1 w-8 block bg-primary-700 rounded mb-2" />
              <span className="h-1 w-8 block bg-primary-700 rounded" />
            </button>
            <div className="">
              <img className="h-auto" src={logo} alt="TranspoPay" />
            </div>
          </div>
          <nav className="grid grid-cols-1 sm:grid-cols-3 gap-4">
            <Link
              className="tracking-wide font-thin"
              to="/"
              onClick={handleScrollTo('home')}
            >
              Home
            </Link>
            <Link
              className="tracking-wide font-thin"
              to="/"
              onClick={handleScrollTo('about')}
            >
              About
            </Link>
            <Link
              className="tracking-wide font-thin"
              to="/"
              onClick={handleScrollTo('feature')}
            >
              Features
            </Link>
          </nav>
          <div className="ml-0 sm:ml-auto mt-auto">
            <a
              className="btn primary block w-32"
              href="mailto:support@multisyscorp.com"
            >
              Contact Us
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}

Header.propTypes = {};

export default Header;
