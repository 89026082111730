import React from 'react';
// import PropTypes from 'prop-types';
import banner from 'assets/img/banner.png';
import main_bg from 'assets/img/main-bg.svg';
import AppStore from '../components/AppStore';

function HeroMain() {
  return (
    <div
      className="bg-white bg-left-bottom bg-repeat-x"
      style={{ backgroundImage: `url(${main_bg})` }}
    >
      <div className="container mx-auto grid grid-cols-1 sm:grid-cols-7 h-hero overflow-hidden">
        <div className="col-span-3 flex flex-col justify-center ">
          <div className="font-medium text-4xl text-primary-700 mb-4 leading-tight">
            Travel Safely and Conveniently
          </div>
          <div className="font-thin leading-6 mb-8 text-sm">
            Transportation is the backbone of the economy. With tremendous
            negative impact of the COVID- 19 pandemic, particularly to the
            commuting public, the Department of Transportation (DOTr) is
            addressing the challenge to recover the economy{' '}
            <span className="font-medium text-primary-500">SAFELY</span> and{' '}
            <span className="font-medium text-primary-500">CONVENIENTLY</span>{' '}
            for the riding public, regardless of the mode of transport.
          </div>
          <div className="mb-48">
            <AppStore />
          </div>
        </div>
        <div className="col-span-4 justify-center items-end hidden sm:flex">
          <div className="w-1/2">
            <img className="w-full" src={banner} alt="banner" />
          </div>
        </div>
      </div>
    </div>
  );
}

HeroMain.propTypes = {};

export default HeroMain;
