import React from 'react';
// import PropTypes from 'prop-types';
import logo from 'assets/img/logo-white.png';
import { Link } from 'react-router-dom';

function Footer() {
  return (
    <div className="bg-primary-600">
      <div className="container mx-auto flex flex-col sm:flex-row items-center justify-center h-32 sm:h-20">
        <div>
          <img className="h-6" src={logo} alt="footer" />
        </div>
        <nav className="grid grid-cols-2 divide-x mx-auto text-sm py-2">
          <Link
            className="px-4 tracking-wide font-thin text-white"
            to="/privacy-policy"
          >
            Privacy Policy
          </Link>
          <Link
            className="px-4 tracking-wide font-thin text-white"
            to="/terms-and-conditions"
          >
            Terms of Use
          </Link>
        </nav>
        <div className="text-gray-200 font-thin text-sm">
          <span>&copy; 2020 TranspoPay v1.0 </span>
        </div>
      </div>
    </div>
  );
}

Footer.propTypes = {};

export default Footer;
