import React from 'react';
// import PropTypes from 'prop-types';
import AppHeader from 'modules/partial/components/AppHeader';
import { Link, Redirect, useParams } from 'react-router-dom';
import ticket_bg from 'assets/img/buy-ticket/ticket-bg.svg';
import pin from 'assets/img/buy-ticket/pin.svg';
import { useApiGet } from 'react-reqq';
import { isEmpty } from 'lodash';
import * as actions from '../actions';
import * as c from '../constants';
import BuyTicket from '../components/BuyTicket';

function BuyTicketCheckout() {
  const { type_id } = useParams();
  const data = useApiGet(c.SELECTED_OPERATOR, {
    // id: 'd9907c22-4c3c-41a8-ad8a-451ea0a281d9',
    // name: 'Metro Rail Transit',
    // type: null,
    // icon: 'https://ucarecdn.com/faadef8b-687f-4a63-9887-84b1f78b2c16/',
  });
  const origin = useApiGet(c.TICKET_ORIGIN, {
    // id: 'b26dc590-f800-43a3-b369-d216864b52b2',
    // name: 'Taft Avenue',
  });
  const destination = useApiGet(c.TICKET_DESTINATION, {
    // id: '26440b54-2be3-4bfb-94d4-4488e56b8f83',
    // name: 'Magallanes',
  });

  React.useEffect(() => {
    if (isEmpty(origin) || isEmpty(destination)) return;
    const params = {
      operator_uuid: type_id,
      origin_uuid: origin.id,
      destination_uuid: destination.id,
    };
    actions.getPrice(params);
  }, [type_id, origin, destination]);

  if (isEmpty(data)) {
    return <Redirect to="/app/buy-ticket" />;
  }
  return (
    <>
      <AppHeader
        title={data.name || 'Undefined'}
        backUrl="/app/buy-ticket"
        primary
      />
      <div className="p-4 mb-8 bg-secondary-200 relative">
        <div className="divide-y-2 divide-primary-200">
          <div className="py-4 pr-4 pl-12">
            <Link to={`/app/buy-ticket/${type_id}/set-origin`}>
              {!isEmpty(origin) ? (
                <span>{origin?.name}</span>
              ) : (
                <span className="text-gray-500">Leaving From</span>
              )}
            </Link>
          </div>
          <div className="py-4 pr-4 pl-12">
            <Link to={`/app/buy-ticket/${type_id}/set-destination`}>
              {!isEmpty(destination) ? (
                <span>{destination?.name}</span>
              ) : (
                <span className="text-gray-500">Going To</span>
              )}
            </Link>
          </div>
        </div>
        <div className="absolute top-0 h-full flex flex-col justify-between py-10 items-center w-12">
          <div>
            <img src={pin} alt="drop" />
          </div>
          <div className="border-r-2 border-primary-500 border-dashed flex-grow my-1" />
          <div>
            <img src={pin} alt="drop" />
          </div>
        </div>
      </div>
      {!isEmpty(origin) && !isEmpty(destination) && (
        <BuyTicket typeId={type_id} />
      )}
      <div className="w-full absolute bottom-0 left-0">
        <img className="w-full" src={ticket_bg} alt="bg" />
      </div>
      <div className="text-xs mt-auto text-black text-center z-10 pb-1">
        v0.1.02
      </div>
    </>
  );
}

BuyTicketCheckout.propTypes = {};

export default BuyTicketCheckout;
