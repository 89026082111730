import React from 'react';
import PropTypes from 'prop-types';
import bus_icon from 'assets/img/buy-ticket/bus-icon.svg';
import chevron from 'assets/img/buy-ticket/chevron-right.svg';
import asset from 'assets/img/buy-ticket/ticket-icon.svg';
import { formatNumber } from 'modules/partial/helper';

function TicketList({ data, isLoading }) {
  const handleSelectRow = (row) => (e) => {
    e.preventDefault();
    console.log('select', row); // eslint-disable-line
  };
  return (
    <div className="flex-grow overflow-auto relative">
      <div className="h-full w-full absolute flex flex-col divide-y">
        {data.length < 1 ? (
          <div className="m-auto">
            <img className="h-20 mx-auto" src={asset} alt="asset" />
            <div className="text-primary-500 font-semibold p-5 text-center w-full">
              {isLoading ? 'Loading...' : 'No Tickets.'}
            </div>
          </div>
        ) : (
          data.map((item) => (
            <a
              className="w-full flex items-center h-16 bg-white px-6 hover:bg-gray-200 py-4"
              key={item.refno}
              href="/"
              onClick={handleSelectRow(item)}
            >
              <div className="mr-4">
                <img className="h-10" src={bus_icon} alt="icon" />
              </div>
              <div>
                <div className="font-light uppercase">{item?.refno}</div>
                <div className="font-light text-xs flex items-center">
                  <span className="max-w-24 truncate">
                    {item?.origin?.name || '-'}
                  </span>
                  <img className="h-3 mx-2" src={chevron} alt="chevron" />
                  <span className="max-w-24 truncate">
                    {item?.destination?.name || '-'}
                  </span>
                </div>
              </div>
              <div className="ml-auto">
                {/* <img className="h-5" src={chevron} alt="chevron" /> */}
                <span className="font-semibold text-green-500 text-xs">
                  &#x20B1; {formatNumber(item?.amount)}
                </span>
              </div>
            </a>
          ))
        )}
      </div>
    </div>
  );
}

TicketList.propTypes = {
  data: PropTypes.instanceOf(Array).isRequired,
  isLoading: PropTypes.bool.isRequired,
};

export default TicketList;
