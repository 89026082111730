import firebase from 'firebase/app';
import 'firebase/database';
import { useEffect } from 'react';
import { req, useApiGet } from 'react-reqq';
import { FIREBASE_CONFIG } from './constants';

export const useFirebase = () => {
  const fbInstance = useApiGet('FIREBASE', null);
  useEffect(() => {
    const fb = firebase.initializeApp(FIREBASE_CONFIG);
    req.set('FIREBASE', fb);
  }, []);
  return fbInstance;
};
