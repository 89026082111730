import React from 'react';
// import PropTypes from 'prop-types'
import { req, useApiGet, useApiLoading } from 'react-reqq';
import DayPicker from 'modules/partial/forms/DayPicker';
import AppHeader from 'modules/partial/components/AppHeader';
import moment from 'moment';
import asset from 'assets/img/buy-ticket/ticket-icon.svg';
import chevron from 'assets/img/buy-ticket/chevron-right.svg';
import { useHistory } from 'react-router-dom';
import { parseTime } from 'modules/partial/helper';
import FooterAction from '../components/FooterAction';
import * as actions from '../actions';
import * as c from '../constants';

function ConductorSchedule() {
  const [filter, setFilter] = React.useState({
    date: moment().format('YYYY-MM-DD'),
  });
  const history = useHistory();
  const list = useApiGet(c.SCHEDULE, []);
  const isLoading = useApiLoading(c.SCHEDULE, 'get');
  const handleSelectRow = (row) => (e) => {
    e.preventDefault();
    req.set(c.SCHEDULE_ITEM, row);
    history.push(`/app/conductor/schedules/${row?.attributes?.uuid}`);
  };
  React.useEffect(() => {
    actions.listSchedule({
      // status: 'PENDING',
      from: filter.date,
      to: filter.date,
      per_page: 50,
    });
  }, [filter]);
  return (
    <>
      <AppHeader title="Trips" primary />
      <div className="flex justify-center mb-2 pt-4 pb-2">
        <DayPicker name="date" onChange={setFilter} value={filter.date} />
      </div>
      <div className="flex-grow overflow-auto relative">
        <div className="h-full w-full absolute flex flex-col divide-y">
          {list.length < 1 ? (
            <div className="m-auto">
              <img className="h-20 mx-auto" src={asset} alt="asset" />
              <div className="text-primary-500 font-semibold p-5 text-center w-full">
                {isLoading ? 'Loading...' : 'No Trips.'}
              </div>
            </div>
          ) : (
            list.map((item) => (
              <a
                className="w-full flex items-center h-16 bg-white px-6 hover:bg-gray-200 py-4"
                key={item.id}
                href="/"
                onClick={handleSelectRow(item)}
              >
                <div>
                  <div className="font-light uppercase">
                    {parseTime(item?.attributes?.time_start)}~
                    {parseTime(item?.attributes?.time_end)}
                  </div>
                  <div className="font-light text-xs flex items-center">
                    <span className="max-w-24 truncate">
                      {item?.included?.origin?.attributes?.description || '-'}
                    </span>
                    <img className="h-3 mx-2" src={chevron} alt="chevron" />
                    <span className="max-w-24 truncate">
                      {item?.included?.destination?.attributes?.description ||
                        '-'}
                    </span>
                  </div>
                </div>
                <div className="ml-auto text-right leading-none">
                  {/* <img className="h-5" src={chevron} alt="chevron" /> */}
                  <div>{c.renderStatus(item?.attributes?.status)}</div>
                  <div>
                    <span className="font-light text-gray-800 text-xs">
                      {item?.included?.vehicle?.attributes?.name || '-'}
                    </span>
                  </div>
                  <div className="text-right font-semibold text-xs truncate">
                    {item?.included?.vehicle?.attributes?.vehicle_number || '-'}
                  </div>
                </div>
              </a>
            ))
          )}
        </div>
      </div>
      <FooterAction />
    </>
  );
}

ConductorSchedule.propTypes = {};

export default ConductorSchedule;
