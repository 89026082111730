import React from 'react';
// import PropTypes from 'prop-types';
import banner from 'assets/img/about-banner.svg';

function About() {
  return (
    <div id="about" className="bg-gradient-primary py-16">
      <div className="container mx-auto flex ">
        <div className="m-auto text-center text-white">
          <div className="leading-none text-4xl mb-4 font-thin">
            What is TranspoPay?
          </div>
          <div className="mx-auto mb-8 font-hairline tracking-wider w-full sm:w-4/6 text-sm">
            TranspoPay is a smartphone-based digital solution that allows{' '}
            <span className="font-medium">CONTACTLESS</span> and{' '}
            <span className="font-medium">CASHLESS</span> payment for any
            transportation ride may it be in trains, buses, jeepneys, shuttles,
            and even tricycles. All rides, one{' '}
            <span className="font-medium">SAFE</span> solution.
          </div>
          <div className="mx-auto w-full sm:w-1/2">
            <img className="w-full" src={banner} alt="banner" />
          </div>
        </div>
      </div>
    </div>
  );
}

About.propTypes = {};

export default About;
