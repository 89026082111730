import { req } from 'react-reqq';
import * as c from './constants';

export const listOperator = () => {
  req.get({
    key: c.OPERATOR,
    url: '/v1/public/operator',
    params: {
      per_page: 1000,
    },
    transform: (res) => {
      const transformer = (row) => ({
        id: row?.attributes?.uuid,
        name: row?.attributes?.business_name,
        type: row?.attributes?.icon,
        icon: row?.attributes?.business_logo || c.OPERATOR_ICON_BY_TYPE.default,
      });
      return (res.data || []).map(transformer);
    },
  });
};

export const listOperatorTerminals = (uuid) => {
  req.get({
    key: c.OPERATOR_TERMINAL,
    url: `/v1/public/operator/${uuid}/branch`,
    params: {
      per_page: 1000,
    },
    transform: (res) => {
      const transformer = (row) => ({
        id: row?.attributes?.uuid,
        name: row?.attributes?.description,
      });
      return (res.data || []).map(transformer);
    },
  });
};

export const getPrice = (params) => {
  req.set(c.GET_PRICE, {});
  req.get({
    key: c.GET_PRICE,
    url: `/v1/public/ticket/fare`,
    headers: (header) => {
      const token = (sessionStorage.getItem('-app-token-') || '').replace(
        /"/g,
        ''
      );
      return {
        ...header,
        Authorization: `Bearer ${token}`,
      };
    },
    params,
    transform: (res) => ({
      isAvailable: !!res?.fare,
      price: res?.fare,
      discountedPrice: res?.discounted_fare,
      isDiscounted: res?.discounted_fare !== res?.fare,
      appliedDiscounts: Array.isArray(res?.applied_discount)
        ? (res?.applied_discount).filter((x) => x !== 'N/A')
        : [],
      // discountedPrice: "9.60",
      // isDiscounted: true,
      // appliedDiscounts: [
      //   "Senior Citizen (20%)"
      // ]
    }),
    onError: () => {
      req.set(c.GET_PRICE, {
        isAvailable: false,
        price: null,
      });
    },
  });
};

export const buyTicket = (payload, onSuccess, onError) => {
  req.post({
    key: c.BUY_TICKET,
    url: () =>
      `${process.env.REACT_APP_WALLET_END_POINT}/api/site/transaction/ticket`,
    headers: (header) => {
      const token = (sessionStorage.getItem('-app-token-') || '').replace(
        /"/g,
        ''
      );
      return {
        ...header,
        Authorization: `Bearer ${token}`,
      };
    },
    payload,
    onSuccess: (res) => {
      onSuccess(res?.response?.data?.refno);
    },
    onError,
  });
};
