import React from 'react';
// import PropTypes from 'prop-types';
import { Route, Switch, useLocation, useRouteMatch } from 'react-router-dom';
import BuyTicketSelection from './BuyTicketSelection';
import BuyTicketOrigin from './BuyTicketOrigin';
import BuyTicketDestination from './BuyTicketDestination';
import BuyTicketCheckout from './BuyTicketCheckout';

function BuyTicketContainer() {
  const location = useLocation();
  const match = useRouteMatch();
  return (
    <div className="h-full w-full flex flex-col relative max-w-lg mx-auto">
      <Switch location={location}>
        <Route exact path={match.path}>
          <BuyTicketSelection />
        </Route>
        <Route exact path={`${match.path}/:type_id`}>
          <BuyTicketCheckout />
        </Route>
        <Route exact path={`${match.path}/:type_id/set-origin`}>
          <BuyTicketOrigin />
        </Route>
        <Route exact path={`${match.path}/:type_id/set-destination`}>
          <BuyTicketDestination />
        </Route>
      </Switch>
    </div>
  );
}

BuyTicketContainer.propTypes = {};

export default BuyTicketContainer;
