import React from 'react';
import PropTypes from 'prop-types';
import AppHeader from 'modules/partial/components/AppHeader';
import { formatDate, parseTime } from 'modules/partial/helper';
import { Link, useParams } from 'react-router-dom';
import { useApiGet, useApiLoading } from 'react-reqq';
import ScheduleTickets from './ScheduleTickets';
import * as actions from '../actions';
import * as c from '../constants';

const ViewItem = ({ label, value }) => {
  const row = typeof value === 'function' ? value() : value;
  return (
    <div>
      <div className="text-xs">{label}: </div>
      <div className="text-sm font-medium">{row}</div>
    </div>
  );
};

ViewItem.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Object)])
    .isRequired,
};

function SelectedConductorSchedule() {
  const { schedule_id } = useParams();
  const data = useApiGet(c.SCHEDULE_ITEM, {});
  const isLoading = useApiLoading(c.SCHEDULE_ITEM, 'get');
  React.useEffect(() => {
    actions.selectSchedule(schedule_id);
  }, [schedule_id]);
  return (
    <>
      <AppHeader title="Schedule" backUrl="/app/conductor/schedules" primary />
      <div className="p-4 border-b">
        <div className="grid grid-cols-2 mb-2 leading-4">
          <ViewItem
            label="Date"
            value={formatDate(data?.attributes?.date, 'MM/DD/YYYY (ddd)')}
          />
          <ViewItem
            label="Time"
            value={() =>
              `${parseTime(data?.attributes?.time_start)}~${parseTime(
                data?.attributes?.time_end
              )}`
            }
          />
        </div>
        <div className="grid grid-cols-2 mb-2 leading-4">
          <ViewItem
            label="Vehicle"
            value={data?.included?.vehicle?.attributes?.name || '-'}
          />
          <ViewItem
            label="Vehicle Number"
            value={data?.included?.vehicle?.attributes?.vehicle_number || '-'}
          />
        </div>
        <div className="grid grid-cols-2 mb-2 leading-4">
          <ViewItem
            label="Origin"
            value={data?.included?.origin?.attributes?.description || '-'}
          />
          <ViewItem
            label="Destination"
            value={data?.included?.destination?.attributes?.description || '-'}
          />
        </div>
        <div className="grid grid-cols-2 mb-2 leading-4">
          <ViewItem
            label="Driver"
            value={data?.included?.driver?.attributes?.name || '-'}
          />
          <ViewItem
            label="Conductor"
            value={data?.included?.conductor?.attributes?.name || '-'}
          />
        </div>
        <div className="text-center">
          {c.renderStatus(data?.attributes?.status || '-')}
        </div>
      </div>
      <ScheduleTickets scheduleUuid={schedule_id} />
      <div className="bg-white border-t p-4">
        {isLoading ? (
          <button
            className="btn primary rounded-full w-full block"
            disabled
            type="button"
          >
            Open Scanner
          </button>
        ) : (
          <Link
            className="btn primary rounded-full w-full block"
            to={`/app/conductor/scanner/${schedule_id}`}
          >
            Open Scanner
          </Link>
        )}
      </div>
    </>
  );
}

SelectedConductorSchedule.propTypes = {};

export default SelectedConductorSchedule;
