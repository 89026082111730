import React from 'react';
// import PropTypes from 'prop-types';
import { useApiLoading } from 'react-reqq';
import AppHeader from 'modules/partial/components/AppHeader';
import FormInput from 'modules/partial/forms/FormInput';
import ticket_bg from 'assets/img/buy-ticket/ticket-bg.svg';
import logo from 'assets/img/logo.svg';
import qs from 'query-string';
import { useLocation } from 'react-router-dom';
import * as actions from '../actions';
import * as c from '../constants';

function ConductorLogin() {
  const location = useLocation();
  const isLoading = useApiLoading(c.AUTH, 'post');
  const [form, setForm] = React.useState({
    email: 'mrt.taft@yopmail.com',
    password: '123123123',
  });
  const handleSubmit = (e) => {
    e.preventDefault();
    actions.login(form);
  };
  const [autoLogin, setAutoLogin] = React.useState(true);
  const [authMessage, setAuthMessage] = React.useState('Authenticating...');
  React.useEffect(() => {
    const query = qs.parse(location.search);
    if (!query.token) {
      setAuthMessage('Auth token is required!');
      return;
    }
    actions.autoLogin(
      query.token,
      () => {
        setAutoLogin(false);
      },
      (res) => {
        setAuthMessage(res?.response?.message || 'Unable to authenticate');
      }
    );
  }, [location.search]);
  if (autoLogin)
    return (
      <div className="bg-white fixed h-screen w-screen top-0 left-0 flex">
        <div className="m-auto flex flex-col justify-center">
          <div className="mx-auto">
            <img className="w-full object-cover" src={logo} alt="logo" />
          </div>
          <div className="text-center mt-4 font-semibold text-primary-500">
            {authMessage}
          </div>
        </div>
      </div>
    );
  return (
    <>
      <AppHeader title=" " backUrl="/app/close" />
      <div className="flex p-4">
        <div className="text-primary-500 text-xl font-light m-auto">
          Conductor Login
        </div>
      </div>
      <form className="p-4 relative z-10" onSubmit={handleSubmit}>
        <div className="mb-2">
          <FormInput
            name="email"
            onChange={setForm}
            value={form.email}
            label="Email Address"
            type="email"
          />
        </div>
        <div className="mb-2">
          <FormInput
            name="password"
            onChange={setForm}
            value={form.password}
            label="Password"
            type="password"
          />
        </div>
        <div className="p-2">
          <button
            className="btn primary w-full"
            type="submit"
            disabled={isLoading}
          >
            Login
          </button>
        </div>
      </form>
      <div className="w-full absolute bottom-0 left-0 pointer-events-none z-0">
        <img className="w-full" src={ticket_bg} alt="bg" />
      </div>
    </>
  );
}

ConductorLogin.propTypes = {};

export default ConductorLogin;
