import React from 'react';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';

function FormInput({ label, name, onChange, value, ...props }) {
  const handleChange = ({ target }) => {
    onChange((state) => ({
      ...state,
      [name]: target.value,
    }));
  };
  return (
    <>
      {!isEmpty(label) && (
        <div className="text-xs text-primary-500 font-semibold">{label}</div>
      )}
      <input
        className="form-control"
        onChange={handleChange}
        value={value}
        {...props}
      />
    </>
  );
}

FormInput.defaultProps = {
  label: '',
};

FormInput.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired,
};

export default React.memo(FormInput);
