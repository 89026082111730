import React from 'react';
import AppHeader from 'modules/partial/components/AppHeader';
// import PropTypes from 'prop-types';
import asset from 'assets/img/buy-ticket/ticket-icon.svg';
import chevron from 'assets/img/buy-ticket/chevron-right.svg';
import { Link, useLocation, useRouteMatch } from 'react-router-dom';
import { req, useApiGet, useApiLoading } from 'react-reqq';
import * as actions from '../actions';
import * as c from '../constants';

function BuyTicketSelection() {
  const match = useRouteMatch();
  const location = useLocation();
  const list = useApiGet(c.OPERATOR, []);
  const isLoading = useApiLoading(c.OPERATOR, 'get');
  const handleSelect = (row) => () => {
    req.set(c.SELECTED_OPERATOR, row);
  };
  React.useEffect(() => {
    actions.listOperator();
    req.set(c.TICKET_ORIGIN, null);
    req.set(c.TICKET_DESTINATION, null);
    req.set(c.OPERATOR_TERMINAL, null);
  }, []);
  return (
    <>
      <AppHeader
        title="Buy Ticket"
        backUrl={location?.state?.backUrl || '/app/close'}
      />
      <div className="bg-gradient-to-b from-white via-white to-secondary-300 flex pb-16 pt-12">
        <div className="m-auto">
          <img className="h-20" src={asset} alt="asset" />
        </div>
      </div>
      <div className="flex items-center px-6 font-thin bg-primary-500 text-white h-12">
        Select Mode of Transportation
      </div>
      <div className="flex-grow overflow-auto relative">
        <div className="h-full w-full absolute  flex flex-col divide-y">
          {list.length < 1 ? (
            <div className="m-auto">
              <div className="text-primary-500 font-semibold p-5 text-center w-full">
                {isLoading ? 'Loading...' : 'No Tickets Available.'}
              </div>
            </div>
          ) : (
            list.map((item) => (
              <Link
                className="w-full flex items-center h-16 bg-white px-6 hover:bg-gray-200"
                key={item.id}
                onClick={handleSelect(item)}
                to={`${match.url}/${item.id}`}
              >
                <div className="mr-4 h-10 w-10 rounded-lg overflow-hidden">
                  <img
                    className="h-full object-cover object-center"
                    src={item.icon}
                    alt="chevron"
                  />
                </div>
                <div className="font-light">{item.name}</div>
                <div className="ml-auto">
                  <img className="h-5" src={chevron} alt="chevron" />
                </div>
              </Link>
            ))
          )}
        </div>
      </div>
    </>
  );
}

BuyTicketSelection.propTypes = {};

export default BuyTicketSelection;
