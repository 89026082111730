import React from 'react';
// import PropTypes from 'prop-types';
import banner from 'assets/img/feature-wallet.png';
import overlay from 'assets/img/feature-wallet-overlay.svg';
import gsap from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';

gsap.registerPlugin(ScrollTrigger);

function FeatureWallet() {
  const overlayRef = React.useRef();
  React.useEffect(() => {
    gsap.from(overlayRef.current, {
      scrollTrigger: {
        trigger: overlayRef.current,
        start: '-220px',
        scrub: 0.5,
      },
      duration: 8,
      opacity: 0,
      y: -400,
    });
  }, []);
  return (
    <div className="bg-white py-12">
      <div className="container mx-auto grid grid-cols-1 sm:grid-cols-6 h-hero overflow-hidden ">
        <div className="col-span-3 flex flex-col justify-center pr-0 sm:pr-16 text-center sm:text-left">
          <div className="text-4xl text-primary-700 mb-4">
            Wide and Diverse Top-up Channels
          </div>
          <div className="font-thin leading-6 mb-8">
            Through seamless and superior integration with various payment
            channels, both digital and brick- and-mortar, ewallet top-up is very
            accessible and convenient.
          </div>
        </div>
        <div className="col-span-3 flex justify-center items-center relative">
          <div className="w-4/6 sm:w-2/6">
            <img className="w-full" src={banner} alt="banner" />
          </div>
          <div ref={overlayRef} className="h-full w-full absolute">
            <img className="w-full" src={overlay} alt="overlay" />
          </div>
        </div>
      </div>
    </div>
  );
}

FeatureWallet.propTypes = {};

export default FeatureWallet;
