import bus_icon from 'assets/img/buy-ticket/bus-icon.svg';
import lrt_icon from 'assets/img/buy-ticket/lrt-icon.svg';
import mrt_icon from 'assets/img/buy-ticket/mrt-icon.svg';

export const TICKET_ORIGIN = 'BUY_TICKET/ticket_origin';
export const TICKET_DESTINATION = 'BUY_TICKET/ticket_destination';
export const OPERATOR = 'BUY_TICKET/operator';
export const SELECTED_OPERATOR = 'BUY_TICKET/selected_operator';
export const OPERATOR_TERMINAL = 'BUY_TICKET/operator_terminal';
export const GET_PRICE = 'BUY_TICKET/get_price';
export const BUY_TICKET = 'BUY_TICKET/buy_ticket';

export const OPERATOR_ICON_BY_TYPE = {
  BUS_1: bus_icon,
  TRAIN_1: lrt_icon,
  TRAIN_2: mrt_icon,
  default: bus_icon,
};
