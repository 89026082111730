/* eslint-disable jsx-a11y/media-has-caption */
import React from 'react';
// import PropTypes from 'prop-types';
import sound1 from 'assets/sound/rip_paper.mp3';
import { useApiGet, req } from 'react-reqq';

const LIBRARY = ['rip_paper'];

function AudioService() {
  const play = useApiGet('PLAY_AUDIO', null);
  React.useEffect(() => {
    if (!play) return;
    if (LIBRARY.indexOf(play) > -1) {
      setTimeout(() => {
        navigator.vibrate(250);
      }, 275);
      const elem = document.getElementById(play);
      if (elem) elem.play();
      return;
    }
    console.warn('Sound not found', play); // eslint-disable-line
  }, [play]);
  const handleEnd = () => {
    req.set('PLAY_AUDIO', null);
  };
  return (
    <>
      <audio
        id="rip_paper"
        controls={false}
        onEnded={handleEnd}
        loop={false}
        preload="auto"
      >
        <source src={sound1} type="audio/mpeg" />
      </audio>
    </>
  );
}

AudioService.propTypes = {};

export default AudioService;
