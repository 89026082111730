import { configureApi } from 'react-reqq';
import Cookie from 'js-cookie';
// import { get } from 'lodash';
// import { toastError } from 'toast';
// import history from './history';

const store = configureApi({
  endpoint: process.env.REACT_APP_END_POINT,
  requestHeaders: () => {
    const token = Cookie.get('token');
    if (!token) return {};
    return {
      Authorization: `Bearer ${token}`,
    };
  },
  timeout: 180000,
  // cacheStorage: 'WEBSQL',
  onError: (err) => {
    // const err_message = get(err, 'response.message');
    // if (err.status === 422) {
    //   const message = get(
    //     err,
    //     `response.errors.${Object.keys(get(err, 'response.errors'))[0]}.0`
    //   );
    //   toastError(message || 'Unprocessable Entity!');
    //   return;
    // }
    // if (err.status === 400) {
    //   toastError(err_message || 'Bad Request!');
    //   return;
    // }
    // if (err.status === 500) {
    //   toastError(err_message || 'Internal Server Error!');
    //   return;
    // }
    // if (err.status === 403) {
    //   toastError(err_message || 'Forbidden!');
    //   if (
    //     get(err, 'response.error') === 'token_expired' ||
    //     get(err, 'response.error') === 'token_invalid'
    //   )
    //     history.push('/logout');
    //   return;
    // }
    // if (err.status === 0) {
    //   toastError('Unable to connect to web service');
    //   return;
    // }
    console.warn('unhandled error', err); // eslint-disable-line
  },
});

export default store;
