export const FIREBASE_CONFIG = {
  apiKey: 'AIzaSyBoaXVrISZRkEfUSz5f4U0h5u_ljja7R-E',
  authDomain: 'autopay-staging.firebaseapp.com',
  databaseURL: 'https://autopay-staging.firebaseio.com',
  projectId: 'autopay-staging',
  storageBucket: 'autopay-staging.appspot.com',
  messagingSenderId: '338791817480',
  appId: '1:338791817480:web:622264633857eb4e174cbd',
  measurementId: 'G-7WHNEY7JFJ',
};
