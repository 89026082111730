import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { formatDate } from '../helper';

function DayPicker({
  name,
  onChange,
  value,
  format,
  dropdownStyle,
  labelWidth,
}) {
  const [showPicker, setShowPicker] = React.useState(false);
  const handleSet = () => {
    console.log('open picker!'); // eslint-disable-line
    setShowPicker(true);
  };
  const handleCancelPicker = () => {
    setShowPicker(false);
  };
  const handleSetDate = (date) => {
    onChange((state) => ({
      ...state,
      [name]: moment(date).format('YYYY-MM-DD'),
    }));
    setShowPicker(false);
  };
  const handlePrev = () => {
    onChange((state) => ({
      ...state,
      [name]: moment(new Date(value)).subtract(1, 'day').format('YYYY-MM-DD'),
    }));
  };
  const handleNext = () => {
    onChange((state) => ({
      ...state,
      [name]: moment(new Date(value)).add(1, 'day').format('YYYY-MM-DD'),
    }));
  };
  return (
    <div className="relative">
      <div id={`day-picker-${name}`} className="btn-group flex items-center">
        <button
          className="btn outline-primary xs"
          type="button"
          onClick={handlePrev}
        >
          Prev
        </button>
        <button
          className="btn outline-primary xs w-48"
          type="button"
          onClick={handleSet}
          style={{ minWidth: labelWidth }}
        >
          {formatDate(value, format)}
        </button>
        <button
          className="btn outline-primary xs"
          type="button"
          onClick={handleNext}
        >
          Next
        </button>
      </div>
      <div
        className={`absolute right-0 z-40 bg-white shadow-lg p-1 bg-gray-50 rounded-lg ${
          showPicker ? ' block' : ' hidden'
        }`}
        style={dropdownStyle}
      >
        <DatePicker onChange={handleSetDate} value={value} inline />
        <button
          className="btn outline-primary xs mb-2 w-full"
          type="button"
          onClick={handleCancelPicker}
        >
          Close
        </button>
      </div>
    </div>
  );
}

DayPicker.propTypes = {
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired,
  format: PropTypes.string,
  dropdownStyle: PropTypes.instanceOf(Object),
  labelWidth: PropTypes.number,
};

DayPicker.defaultProps = {
  format: 'dddd, MMM DD, YYYY',
  dropdownStyle: {},
  labelWidth: 130,
};

export default DayPicker;
