import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import Landing from 'modules/partial/containers/Landing';
import TermsContainer from 'modules/partial/containers/TermsContainer';
import PrivacyContainer from 'modules/partial/containers/PrivacyContainer';
import AppTerms from 'modules/partial/containers/AppTerms';
import AppPrivacy from 'modules/partial/containers/AppPrivacy';
import AppPrivate from './AppPrivate';

function Public() {
  return (
    <Switch>
      <Route exact path="/" component={Landing} />
      <Route path="/app" component={AppPrivate} />
      <Route path="/privacy" component={AppPrivacy} />
      <Route path="/terms" component={AppTerms} />
      <Route path="/terms-and-conditions" component={TermsContainer} />
      <Route path="/privacy-policy" component={PrivacyContainer} />
      <Route path="/logout">
        <Redirect to="/" />
      </Route>
    </Switch>
  );
}

Public.propTypes = {};

export default Public;
