import React from 'react';
// import PropTypes from 'prop-types';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import { useApiGet } from 'react-reqq';
import { usePersistState } from 'modules/partial/helper';
import ConductorLogin from './ConductorLogin';
import ConductorHome from './ConductorHome';
import SelectedConductorSchedule from './SelectedConductorSchedule';
import ConductorSchedule from './ConductorSchedule';
import ConductorScanner from './ConductorScanner';
import ConductorNotFound from './ConductorNotFound';
import * as c from '../constants';

const PrivateRoute = (props) => {
  const [auth] = usePersistState('conductor-auth', {
    isAuthenticated: false,
    profile: {},
  });
  const authState = useApiGet(c.AUTH, auth);
  if (!authState.isAuthenticated) return <Route component={ConductorLogin} />;
  return <Route {...props} />;
};

function ConductorRoutes() {
  const match = useRouteMatch();
  return (
    <div className="h-full w-full flex flex-col relative max-w-lg mx-auto">
      <Switch>
        <PrivateRoute exact path={match.path} component={ConductorHome} />
        <PrivateRoute
          exact
          path={`${match.path}/scanner/:trip_schedule_uuid`}
          component={ConductorScanner}
        />
        <PrivateRoute
          exact
          path={`${match.path}/schedules/:schedule_id`}
          component={SelectedConductorSchedule}
        />
        <PrivateRoute
          exact
          path={`${match.path}/schedules`}
          component={ConductorSchedule}
        />
        <PrivateRoute component={ConductorNotFound} />
      </Switch>
    </div>
  );
}

ConductorRoutes.propTypes = {};

export default ConductorRoutes;
