import React from 'react';
// import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import { CalendarIcon, HomeIcon, UserIcon } from 'modules/partial/icons';

function FooterAction() {
  return (
    <div className="bg-white border-t grid grid-cols-3 divide-x text-gray-600">
      <NavLink
        className="py-2 text-center"
        activeClassName="text-white bg-primary-500"
        to="/app/conductor"
        exact
      >
        <div className="flex justify-center">
          <HomeIcon />
        </div>
        <div className="text-xs">Home</div>
      </NavLink>
      <NavLink
        className="py-2 text-center"
        activeClassName="text-white bg-primary-500"
        to="/app/conductor/schedules"
      >
        <div className="flex justify-center">
          <CalendarIcon />
        </div>
        <div className="text-xs">Trips</div>
      </NavLink>
      <NavLink
        className="py-2 text-center"
        activeClassName="text-white bg-primary-500"
        to="/app/conductor/account"
      >
        <div className="flex justify-center">
          <UserIcon />
        </div>
        <div className="text-xs">Account</div>
      </NavLink>
      {/* <NavLink className="py-2 text-center" activeClassName="text-white bg-primary-500" to="/app/conductor/scanner">
        <div className="flex justify-center"><QrIcon /></div>
        <div className="text-xs">Scanner</div>
      </NavLink> */}
    </div>
  );
}

FooterAction.propTypes = {};

export default FooterAction;
