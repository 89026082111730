import { OPERATOR_ICON_BY_TYPE } from 'modules/buy-ticket/constants';

export const transformTicket = (data) => ({
  refno: data?.attributes?.refno,
  ticket: {
    id: data?.attributes?.operator?.uuid,
    name: data?.attributes?.operator?.business_name,
    type: data?.attributes?.operator?.icon,
    instructions: data?.attributes?.operator?.ticket_instructions,
    icon:
      data?.attributes?.operator?.business_logo ||
      OPERATOR_ICON_BY_TYPE.default,
  },
  origin: {
    id: data?.attributes?.origin?.uuid,
    name: data?.attributes?.origin?.description,
  },
  destination: {
    id: data?.attributes?.destination?.uuid,
    name: data?.attributes?.destination?.description,
  },
  amount: data?.attributes?.fare,
  status: data?.attributes?.status,
});

export const transformTicket2 = (data) => {
  const t = {
    refno: data?.refno,
    ticket: {
      id: data?.operator?.uuid,
      name: data?.operator?.business_name,
      type: data?.operator?.icon,
      instructions: data?.operator?.ticket_instructions,
      icon: data?.operator?.business_logo || OPERATOR_ICON_BY_TYPE.default,
    },
    origin: {
      id: data?.origin?.uuid,
      name: data?.origin?.description,
    },
    destination: {
      id: data?.destination?.uuid,
      name: data?.destination?.description,
    },
    amount: data?.fare_amount,
    status: data?.status,
  };
  return t;
};
