import React from 'react';
// import PropTypes from 'prop-types';
import Privacy from '../components/Privacy';

function AppPrivacy() {
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <div className="container mx-auto pb-12">
        <Privacy />
      </div>
    </>
  );
}

AppPrivacy.propTypes = {};

export default AppPrivacy;
