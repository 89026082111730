import React from 'react';
import PropTypes from 'prop-types';
import { formatNumber } from 'modules/partial/helper';
import { useApiGet, useApiLoading } from 'react-reqq';
import * as actions from '../actions';
import * as c from '../constants';
import TicketList from '../components/TicketList';

function ScheduleTickets({ scheduleUuid }) {
  const aggs = useApiGet(c.TICKET_AGGS, {});
  const list = useApiGet(c.TICKET, []);
  const isListLoading = useApiLoading(c.TICKET, 'get');
  React.useEffect(() => {
    actions.getDashboard({
      trip_schedule_uuid: scheduleUuid,
    });
    actions.listTicket({
      trip_schedule_uuid: scheduleUuid,
    });
  }, [scheduleUuid]);
  return (
    <>
      <div className="border-b py-2">
        <div className="grid grid-cols-2 gap-2">
          <div className="text-center">
            <div className="text-sm text-gray-600"># of Transactions:</div>
            <div className="text-lg text-gray-800">
              {formatNumber(aggs.total_count || '0', 0)}
            </div>
          </div>
          <div className="text-center">
            <div className="text-sm text-gray-600">Total Amount:</div>
            <div className="text-lg text-gray-800">
              {formatNumber(aggs.total_amount || '0')}
            </div>
          </div>
        </div>
      </div>
      <TicketList data={list} isLoading={isListLoading} />
    </>
  );
}

ScheduleTickets.propTypes = {
  scheduleUuid: PropTypes.string.isRequired,
};

export default ScheduleTickets;
