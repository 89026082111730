import React from 'react';
// import PropTypes from 'prop-types';
import AppHeader from 'modules/partial/components/AppHeader';
import { storage } from 'modules/partial/helper';
import { req, useApiGet, useApiLoading } from 'react-reqq';
import moment from 'moment';
import ConductorDashboard from './ConductorDashboard';
import FooterAction from '../components/FooterAction';
import TicketList from '../components/TicketList';
import * as actions from '../actions';
import * as c from '../constants';

function ConductorHome() {
  const [filter, setFilter] = React.useState({
    date: moment().format('YYYY-MM-DD'),
  });
  const list = useApiGet(c.TICKET, []);
  const isLoading = useApiLoading(c.TICKET, 'get');
  const handleLogout = () => {
    storage.remove('conductor-auth');
    req.set(c.AUTH, { isAuthenticated: false });
    window.location.href = '/app/close';
  };
  React.useEffect(() => {
    actions.listTicket({
      from: filter.date,
      to: filter.date,
    });
  }, [filter]);
  return (
    <>
      <AppHeader title="Ticket History" backUrl={handleLogout} primary />
      <ConductorDashboard filter={filter} setFilter={setFilter} />
      <TicketList data={list} isLoading={isLoading} />
      <FooterAction />
      {/* <div className="bg-white border-t p-4">
        <Link
          className="btn primary rounded-full w-full block"
          to={`${match.url}/scanner`}
        >
          Open Scanner
        </Link>
      </div> */}
    </>
  );
}

ConductorHome.propTypes = {};

export default ConductorHome;
