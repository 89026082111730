import React from 'react';
// import PropTypes from 'prop-types';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import chevron from 'assets/img/buy-ticket/chevron-right.svg';
import ticketTop from 'assets/img/buy-ticket/ticket-top.png';
import ticketBottom from 'assets/img/buy-ticket/ticket-bottom.png';
import pin from 'assets/img/buy-ticket/pin.svg';
import AppHeader from 'modules/partial/components/AppHeader';
import { formatNumber } from 'modules/partial/helper';
import { req, useApiGet, useApiLoading } from 'react-reqq';
import Barcode from 'react-barcode';
import QRCode from 'qrcode.react';
import * as actions from '../actions';
import * as c from '../constants';

function TicketDetail() {
  const { refno } = useParams();
  const history = useHistory();
  const location = useLocation();
  const isLoading = useApiLoading(c.TICKET, 'get');
  const data = useApiGet(c.TICKET, {});
  const handleGoBack = () => {
    if (location?.state?.backUrl) {
      history.push(location?.state?.backUrl);
      return;
    }
    window.location.href = location?.state?.backUrl || '/app/close';
  };
  const firebase = useApiGet('FIREBASE', null);
  React.useEffect(() => {
    if (!firebase) return () => {};
    const database = firebase.database();
    const dbRef = database.ref(`ticket/${refno}`);
    dbRef.on('value', (snap) => {
      const value = snap.val();
      if (value?.status === 'USED') {
        actions.setTicketState({ status: 'USED' });
      }
      if (value?.status === 'PENDING') {
        actions.setTicketState({ status: 'PENDING' });
      }
    });
    return () => {
      try {
        dbRef.off();
      } catch (err) {
        // eslint-disable-line
      }
    };
  }, [firebase, refno]);
  React.useEffect(() => {
    actions.getTicket(refno);
    return () => {
      req.set(c.TICKET, null);
    };
  }, [refno]);
  React.useEffect(() => {
    if (data.status === 'USED') req.set('PLAY_AUDIO', 'rip_paper');
  }, [data.status]);
  return (
    <>
      <AppHeader title="Ticket Details" backUrl={handleGoBack} secondary />
      <div className="flex-grow overflow-auto relative bg-secondary-500">
        <div className="h-full w-full absolute flex flex-col px-8">
          <div className="w-full">
            <img className="w-full" src={ticketTop} alt="top" />
          </div>
          <div className="bg-white relative">
            {isLoading && (
              <div className="absolute h-full w-full top-0 left-0 z-50">
                <div className="w-32 h-4 bg-white block ml-10 mt-5">
                  <div className="bg-gray-200 animate-pulse h-full w-full" />
                </div>
                <div className="w-48 h-4 bg-white block ml-10 mt-1 mb-1">
                  <div className="bg-gray-200 animate-pulse h-full w-full" />
                </div>
                <div className="w-64 h-20 bg-white block mx-auto mt-6">
                  <div className="bg-gray-200 animate-pulse h-full w-full" />
                </div>
                <div className="w-56 h-56 bg-white block mx-auto mt-10">
                  <div className="bg-gray-200 animate-pulse h-full w-full" />
                </div>
              </div>
            )}
            <div className="flex items-center p-4 border-b">
              <div className="mr-2 mb-auto">
                <img
                  className="h-5"
                  src={data?.ticket?.icon || pin}
                  alt="icon"
                />
              </div>
              <div>
                <div className="font-light">{data?.ticket?.name || '-'}</div>
                <div className="font-light text-xs flex items-center">
                  <span className="max-w-24 truncate text-gray-500">
                    {data?.origin?.name || '-'}
                  </span>
                  <img className="h-3 mx-2" src={chevron} alt="chevron" />
                  <span className="max-w-24 truncate text-gray-500">
                    {data?.destination?.name || '-'}
                  </span>
                </div>
              </div>
              <div className="font-bold text-green-500 text-xs ml-auto whitespace-no-wrap">
                &#x20B1; {formatNumber(data.amount || 0)}
              </div>
            </div>
            <div
              className="py-2 px-4 flex justify-center"
              style={{ minHeight: 102 }}
            >
              {data.refno && (
                <Barcode height={50} value={data.refno} fontSize={12} />
              )}
            </div>
          </div>
          <div className="h-0 w-full" style={{ marginTop: '-1px' }}>
            <div
              className={`h-12 bg-white ${
                data?.status === 'USED' ? 'rip-paper-bottom' : ''
              } border-t-4 border-dashed`}
            />
          </div>
          <div
            id="receipt"
            className={
              data?.status === 'USED' ? 'animate rip pointer-events-none' : ''
            }
          >
            {data?.status === 'USED' && (
              <div className="h-0 w-full">
                <div className="h-12 bg-white rip-paper-top" />
              </div>
            )}
            <div className="relative">
              {data?.status === 'USED' ? (
                <div
                  className="absolute w-full bg-white mt-12"
                  style={{ height: 'calc(100% - 3rem)' }}
                />
              ) : (
                <div
                  className="absolute w-full bg-white"
                  style={{ height: `calc(100% - 4px)`, marginTop: '4px' }}
                />
              )}
              <div
                className="relative flex flex-col justif-center px-4 py-8"
                style={{ minHeight: 264 }}
              >
                {data.refno && (
                  <div className="m-auto">
                    <QRCode size={200} fgColor="#386fa4" value={data.refno} />
                  </div>
                )}
                {data.status === 'PENDING' && data?.ticket?.instructions && (
                  <div className="text-center mt-3 text-sm leading-none">
                    {data?.ticket?.instructions}
                  </div>
                )}
              </div>
            </div>
            <div className="w-full">
              <img
                className="w-full ticket-shadow"
                src={ticketBottom}
                alt="bottom"
              />
            </div>
          </div>
          {location?.state?.backUrl === '/app/close' && (
            <div className="py-4">
              <button
                className="btn primary w-full"
                type="button"
                onClick={handleGoBack}
              >
                Back to Home
              </button>
            </div>
          )}
        </div>
      </div>
    </>
  );
}

TicketDetail.propTypes = {};

export default TicketDetail;
