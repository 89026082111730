import React from 'react';
import PropTypes from 'prop-types';
import QrReader from 'react-qr-reader';
import { SyncIcon } from '../icons';

const ERROR = {
  'NotAllowedError: Permission denied': 'Please allow camera permission',
};

const ENABLE_CAMERA = true;

function QrScanner({ onScan }) {
  const [, setStyle] = React.useState({});
  const [cameraStyle, setCameraStyle] = React.useState({});
  const [facingMode, setFacingMode] = React.useState('environment');
  React.useEffect(() => {
    const wrapper = document.getElementById('qr-scan-wrapper');
    const backup_wrapper = document.getElementById('scanner-wrapper');
    setStyle({
      height: backup_wrapper?.offsetHeight || wrapper.offsetHeight,
      width: backup_wrapper?.offsetWidth || wrapper.offsetWidth,
    });
  }, []);

  const isCameraLoaded = React.useRef();

  const [cameraLoaded, setCameraLoaded] = React.useState(false);

  const handleLoaded = () => {
    if (isCameraLoaded.current) return;
    setTimeout(() => {
      const scanner = document.getElementById('qr-scanner');
      const sH = scanner.offsetHeight;
      const sW = scanner.offsetWidth;
      setStyle((state) => {
        const multiplier = state.height / sH;
        const newWidth = sW * multiplier;
        setCameraStyle({
          height: state.height,
          width: newWidth,
          left: '50%',
          transform: 'translateX(-50%)',
          id: new Date().getTime(),
        });
        isCameraLoaded.current = true;
        setTimeout(() => setCameraLoaded(true), 1000);
        return state;
      });
    }, 200);
  };

  const handleScan = (res) => {
    if (!res) return;
    onScan(res);
  };
  const [error, setError] = React.useState(null);
  const handleError = (err) => {
    setError(err.toString());
  };
  const handleToggle = () => {
    setFacingMode((state) =>
      state === 'environment' ? 'user' : 'environment'
    );
  };
  const [enterCode, setEnterCode] = React.useState('');
  return (
    <div
      id="qr-scan-wrapper"
      className="flex flex-grow relative overflow-hidden"
    >
      <div
        className={`pointer-events-none absolute h-full w-full flex bg-black z-30 transition-opacity	duration-1000 ease-in-out ${
          !cameraLoaded || error ? 'opacity-100' : 'opacity-0'
        }`}
      >
        <div className="text-white m-auto">
          {error
            ? ERROR[error] || 'Device camera not supported'
            : 'Loading Camera...'}
        </div>
      </div>
      <div className="absolute z-40 p-4 w-full mt-12 flex">
        <button
          className="btn link text-white xs ml-auto"
          type="button"
          onClick={handleToggle}
        >
          <div>
            <SyncIcon />
          </div>
        </button>
        {!ENABLE_CAMERA && (
          <button
            className="btn"
            type="button"
            onClick={() => onScan('ref-1234')}
          >
            SCAN
          </button>
        )}
      </div>
      <div className="absolute z-40 p-4 w-full left-0 bottom-0 flex bg-black bg-opacity-25">
        <form
          className="text-white w-full"
          onSubmit={(e) => {
            e.preventDefault();
            try {
              onScan(enterCode);
              document.activeElement.blur();
            } catch (err) {
              // do nothing...
            }
          }}
        >
          <input
            className="w-full py-2 text-center bg-transparent uppercase"
            placeholder="Enter Code"
            onChange={({ target }) => setEnterCode(target.value)}
            value={enterCode}
            onBlur={() => setEnterCode('')}
          />
        </form>
      </div>
      {!error && (
        <div className="absolute h-full w-full flex z-30">
          <div
            id="scanner-border"
            className="rounded-xl m-auto"
            style={{ height: '18rem', width: '18rem' }}
          />
        </div>
      )}
      <div id="qr-scanner" className="absolute w-full z-20" style={cameraStyle}>
        {ENABLE_CAMERA && (
          <QrReader
            key={cameraStyle.id}
            facingMode={facingMode}
            style={{ width: '100%' }}
            delay={50}
            onScan={handleScan}
            onError={handleError}
            onLoad={handleLoaded}
            showViewFinder={false}
          />
        )}
      </div>
    </div>
  );
}

QrScanner.propTypes = {
  onScan: PropTypes.func.isRequired,
};

export default QrScanner;
