import React from 'react';
// import PropTypes from 'prop-types';
import { Link, useHistory, useRouteMatch } from 'react-router-dom';
import AppHeader from 'modules/partial/components/AppHeader';
import bus_icon from 'assets/img/buy-ticket/bus-icon.svg';
import chevron from 'assets/img/buy-ticket/chevron-right.svg';
import asset from 'assets/img/buy-ticket/ticket-icon.svg';
import { useApiGet, useApiLoading } from 'react-reqq';
import * as actions from '../actions';
import * as c from '../constants';

function TicketContainer() {
  const match = useRouteMatch();
  const history = useHistory();
  const list = useApiGet(c.TICKET_LIST, []);
  const isLoading = useApiLoading(c.TICKET_LIST, 'get');
  const handleSelectRow = (item) => (e) => {
    e.preventDefault();
    history.push({
      pathname: `${match.url}/${item.refno}`,
      state: {
        backUrl: match.url,
      },
    });
  };
  React.useEffect(() => {
    actions.listTicket({ status: 'PENDING' });
  }, []);
  return (
    <>
      <AppHeader title="Available Tickets" backUrl="/app/close" primary />
      <div className="flex-grow overflow-auto relative">
        <div className="h-full w-full absolute flex flex-col divide-y">
          {list.length < 1 ? (
            <div className="m-auto">
              <img className="h-20 mx-auto" src={asset} alt="asset" />
              <div className="text-primary-500 font-semibold p-5 text-center w-full">
                {isLoading ? 'Loading...' : 'No Tickets purchased yet'}
              </div>
              <Link
                className={`btn primary w-40 block mx-auto ${
                  isLoading ? 'opacity-0' : ''
                }`}
                to={{
                  pathname: '/app/buy-ticket',
                  state: { backUrl: match.url },
                }}
              >
                Buy Ticket
              </Link>
            </div>
          ) : (
            list.map((item) => (
              <a
                className="w-full flex items-center h-16 bg-white px-6 hover:bg-gray-200"
                key={item.refno}
                href="/"
                onClick={handleSelectRow(item)}
              >
                <div className="mr-4">
                  <img
                    className="h-10"
                    src={item?.ticket?.icon || bus_icon}
                    alt="icon"
                  />
                </div>
                <div>
                  <div className="font-light">{item.ticket.name}</div>
                  <div className="font-light text-xs flex items-center">
                    <span className="max-w-24 truncate">
                      {item.origin.name}
                    </span>
                    <img className="h-3 mx-2" src={chevron} alt="chevron" />
                    <span className="max-w-24 truncate">
                      {item.destination.name}
                    </span>
                  </div>
                </div>
                <div className="ml-auto">
                  <img className="h-5" src={chevron} alt="chevron" />
                </div>
              </a>
            ))
          )}
        </div>
      </div>
    </>
  );
}

TicketContainer.propTypes = {};

export default TicketContainer;
