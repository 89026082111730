import { useState, useCallback, useEffect } from 'react';
import { get, debounce, forOwn, isEmpty } from 'lodash';
import moment from 'moment';

export const parseNumber = (str, default_value = false) => {
  const v = parseFloat(`${str}`.replace(/,/g, ''));
  // eslint-disable-next-line no-restricted-globals
  if (isNaN(v)) return typeof default_value !== 'boolean' ? default_value : str;
  return v;
};

export const parseTime = (time, format = 'hh:mm A') => {
  if (!time) return '';
  const d = moment(time, ['HH:mm:ss']);
  if (!d.isValid()) return '';
  return d.format(format);
};

export const formatNumber = (v, decimal = 2) => {
  try {
    const n = parseNumber(v);
    // eslint-disable-next-line no-restricted-globals
    if (isNaN(n)) return v;
    return n.toLocaleString(undefined, {
      minimumFractionDigits: decimal,
      maximumFractionDigits: decimal,
    });
  } catch (err) {
    return v;
  }
};

export const formatDate = (date, format = 'MM/DD/YYYY', defaultValue = '-') => {
  if (!date) return defaultValue;
  const d = new Date(date);
  if (d.toString() === 'Invalid Date') return defaultValue;
  return moment(d).format(format);
};

export const storage = {
  get: (key, defaultValue = false) => {
    try {
      return JSON.parse(sessionStorage.getItem(key));
    } catch (err) {
      return defaultValue;
    }
  },
  set: (key, value) => {
    const newValue = JSON.stringify(value);
    sessionStorage.setItem(key, newValue);
  },
  remove: (key) => {
    sessionStorage.removeItem(key);
  },
};

export const usePersistState = (key, state, config = {}) => {
  const [value, setValue] = useState(storage.get(key) || state);
  const updateState = useCallback(
    debounce((newState) => {
      storage.set(key, newState);
    }, 500),
    []
  );
  useEffect(() => {
    updateState(value);
  }, [value]); // eslint-disable-line
  useEffect(() => {
    return () => {
      if (!config.keepOnUnmount) {
        storage.remove(key);
      }
    };
  }, []); // eslint-disable-line
  return [value, setValue];
};

export const removePersistState = (key) => {
  storage.remove(key);
};

export const transformIncluded = (x, included) => {
  if (!included || isEmpty(included)) return x;
  const rowIncluded = {};
  forOwn(x.relationships, (v, k) => {
    rowIncluded[k] = Array.isArray(v.data)
      ? v.data.map(
          (z) =>
            included.find(
              (y) => y.type === get(z, 'type') && y.id === get(z, 'id')
            ) || {}
        )
      : included.find(
          (y) => y.type === get(v, 'data.type') && y.id === get(v, 'data.id')
        ) || {};
  });
  const { links, relationships, type, ...rest } = x;
  return { ...rest, included: rowIncluded };
};
