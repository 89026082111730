import React from 'react';

export const AUTH = 'CONDUCTOR/auth';
export const TICKET = 'CONDUCTOR/ticket';
export const SCAN = 'CONDUCTOR/scan';
export const TICKET_AGGS = 'CONDUCTOR/ticket_aggs';

export const SCHEDULE = 'CONDUCTOR/schedule';
export const SCHEDULE_ITEM = 'CONDUCTOR/schedule_item';
export const SCHEDULE_ITEM_AGGS = 'CONDUCTOR/schedule_item_aggs';

export const renderStatus = (status) => {
  if (status === 'PENDING') {
    return (
      <span className="font-semibold text-orange-500 text-xs">
        {status || '-'}
      </span>
    );
  }
  if (status === 'SUCCESS') {
    return (
      <span className="font-semibold text-green-500 text-xs">
        {status || '-'}
      </span>
    );
  }
  if (status === 'FAILED') {
    return (
      <span className="font-semibold text-red-500 text-xs">
        {status || '-'}
      </span>
    );
  }
  return (
    <span className="font-semibold text-gray-800 text-xs">{status || '-'}</span>
  );
};
