import React from 'react';
// import PropTypes from 'prop-types';
import AppHeader from 'modules/partial/components/AppHeader';
import FooterAction from '../components/FooterAction';

function ConductorNotFound() {
  return (
    <>
      <AppHeader title="Coming Soon" primary />
      <div className="flex-grow overflow-auto relative">
        <div className="h-full w-full absolute flex flex-col divide-y">
          <div className="m-auto">Work in Progress</div>
        </div>
      </div>
      <FooterAction />
    </>
  );
}

ConductorNotFound.propTypes = {};

export default ConductorNotFound;
