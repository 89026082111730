import React, { Suspense, lazy } from 'react';
import { useAuth } from 'modules/auth/hooks';
import ErrorBoundary from 'modules/partial/containers/ErrorBoundary';
import Public from './Public';

const Private = lazy(() => import('./Private'));

function App() {
  const [isAppReady, isAuthenticated] = useAuth();
  if (!isAppReady) return null;
  return (
    <ErrorBoundary>
      {isAuthenticated ? (
        <Suspense fallback={<div>Loading...</div>}>
          <Private />
        </Suspense>
      ) : (
        <Public />
      )}
    </ErrorBoundary>
  );
}

export default App;
